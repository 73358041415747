<template>
  <div>
    <div class="print-button" @click="onPrintAll">
      <svg
        style="margin-right: 1rem"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 36 36"
      >
        <path
          fill="currentColor"
          d="M29 9h-2V5H9v4H7a4 4 0 0 0-4 4v11h3.92v-1.91H5V13a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v9h-1.92v2H33V13a4 4 0 0 0-4-4Zm-4 0H11V7h14Z"
          class="clr-i-outline clr-i-outline-path-1"
        />
        <path
          fill="currentColor"
          d="M28 18H8a1 1 0 0 0 0 2h1v12h18V20h1a1 1 0 0 0 0-2Zm-3 12H11V20h14Z"
          class="clr-i-outline clr-i-outline-path-2"
        />
        <path
          fill="currentColor"
          d="M27 13.04h2v2h-2z"
          class="clr-i-outline clr-i-outline-path-3"
        />
        <path fill="none" d="M0 0h36v36H0z" />
      </svg>
      Print Slip
    </div>
    <div class="print-section">
      <div v-for="(detail, index) in fulfillmentDetails" :key="index" class="page-section">
        <h3 class="print-title">
          Picking List
        </h3>
        <table v-for="(fulfillment, idx) in detail?.fulfillments" :key="fulfillment.id" width="100%" class="print-detail">
          <tr v-if="idx == 0">
            <td class="col-detail">
              ID Pengiriman
            </td>
            <td>: {{ fulfillment?.fulfillment_number }}</td>
          </tr>
          <tr v-if="idx == 0">
            <td class="col-detail">
              Bisnis
            </td>
            <td>: {{ detail?.business_title || detail?.business_name }}</td>
          </tr>
          <tr v-if="idx == 0">
            <td class="col-detail">
              Gudang
            </td>
            <td>: {{ detail?.warehouse_name }}</td>
          </tr>
          <tr v-if="idx == 0">
            <td class="col-detail">
              Nomor Order
            </td>
            <td>: {{ detail?.order?.order_number }}</td>
          </tr>
          <tr v-if="idx == 0">
            <td class="col-detail">
              Tgl Proses Fulfillment
            </td>
            <td>: {{ fulfillment?.shipping?.shipping_date }}</td>
          </tr>
          <tr v-if="idx == 0">
            <td class="col-detail">
              Alamat Pengiriman
            </td>
            <td>
              :
              {{ fulfillment?.destination?.address?.line1 }}
              {{ fulfillment?.destination?.address?.line2 }}
              {{ fulfillment?.destination?.address?.line1 }},
              {{ fulfillment?.destination?.address?.sub_district }},
              {{ fulfillment?.destination?.address?.district }},
              {{ fulfillment?.destination?.address?.city }},
              {{ fulfillment?.destination?.address?.province }},
              {{ fulfillment?.destination?.address?.postal_code }}
            </td>
          </tr>
          <tr v-if="idx == 0">
            <td class="col-detail">
              Kurir
            </td>
            <td>: {{ fulfillment?.shipping?.courier }}</td>
          </tr>
          <tr>
            <td colspan="2" class="p-0">
              <table width="100%" class="print-table">
                <thead>
                  <tr>
                    <th style="width: 30%">
                      SKU
                    </th>
                    <th style="width: 40%">
                      Nama Produk
                    </th>
                    <th style="width: 15%">
                      Qty
                    </th>
                    <th style="width: 15%">
                      UOM
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in fulfillment?.line_items" :key="item.id">
                    <td>{{ item?.sku }}</td>
                    <td>{{ item?.catalog_title }}</td>
                    <td>{{ item?.quantity }}</td>
                    <td>{{ item?.uom }}</td>
                  </tr>
                  <template v-if="fulfillment?.line_items?.length < 6">
                    <tr v-for="i in 6 - fulfillment.line_items.length" :key="i">
                      <td v-for="j in 4" :key="j">
                        &nbsp;
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="p-0">
              <table width="100%" class="print-footer">
                <tr>
                  <td>Picker</td>
                  <td>Checker</td>
                  <td>Supervisor</td>
                </tr>
                <tr class="footer-line">
                  <td>
                    <div>&nbsp;</div>
                    <p>Tgl:</p>
                  </td>
                  <td>
                    <div>&nbsp;</div>
                    <p>Tgl:</p>
                  </td>
                  <td>
                    <div>&nbsp;</div>
                    <p>Tgl:</p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getFulfillmentDetail } from '@/api/fulfillment'
import { getWarehouseById } from '@/api/warehouse'

export default {
  name: 'FulfillmentPicking',
  data() {
    const INIT_FULFILLMENT = {
      business_id: '',
      business_name: '',
      business_title: '',
      fulfillments: [],
      order: {
        id: '',
        order_date: 0,
        order_number: '',
        state: '',
        channel: {
          id: 0,
          code: '',
          name: '',
        },
        warehouse_id: '',
        warehouse_name: '',
      },
    }

    return {
      fulfillmentDetails: [],
      defaultFulfillment: Object.assign({}, INIT_FULFILLMENT),
      defaultWarehouseDetail: {
        business_name: '',
        business_title: '',
        warehouse_id: '',
        warehouse_name: '',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const fulfillmentIds = this.$route?.query?.fulfillment_id
        ? this.$route.query.fulfillment_id.split(',')
        : []
      let warehouseIds = []
      let warehouseDetails = []

      this.fulfillmentDetails = await Promise.all(
        fulfillmentIds.map(async fulfillment_id => {
          return await getFulfillmentDetail({
            url: 'fulfillment',
            fulfillment_id,
          })
          .then(async ({ data: { data: response } }) => {
            let warehouseDetail = {}

            if(response?.warehouse_id) {
              if(!warehouseIds.includes(response.warehouse_id)) {
                warehouseDetail = await getWarehouseById(response.warehouse_id)
                  .then(({ data: { data: response } }) => ({
                      business_name: response?.business_name,
                      business_title: response?.business_title,
                      warehouse_id: response.warehouse_id,
                      warehouse_name: response?.name,
                  }))
                  .catch(() => this.defaultWarehouseDetail)

                await warehouseDetails.push(warehouseDetail)
                warehouseIds.push(response.warehouse_id)
              } else {
                const findWarehouse = warehouseDetails.find(warehouse => warehouse.warehouse_id === response.warehouse_id)
                warehouseDetail = findWarehouse?.warehouse_id
                  ? findWarehouse
                  : this.defaultWarehouseDetail
              }
            } else {
              warehouseDetail = this.defaultWarehouseDetail
            }

            return await {
              ...response,
              ...warehouseDetail,
            }
          })
          .catch(() => this.defaultFulfillment)
        }),
      )
    },
    onPrintAll() {
      window.print()
    },
  },
}
</script>

<style scoped>
.print-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #333;
  padding: 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 2rem;
  max-width: 290mm;
  margin: 2rem auto;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 20px;
}

.print-button img {
  margin-right: 1rem;
}

.print-section {
  width: 60%;
  margin: 0 auto;
}

.page-section {
  width: 100%;
  padding: 20px;
  margin-bottom: 40px;
  border: 1px solid black;
  border-collapse: collapse;
}

.print-title {
  display: none;
  margin: 0 auto 60px;
  text-align: center;
  font-weight: bold;
}

.print-detail td {
  padding: 4px 0;
}

.print-detail .col-detail {
  width: 20%;
  vertical-align: top;
}

.print-table {
  margin: 20px 0;
}

.print-detail .col-detail,
.print-table th {
  font-weight: bold;
}

.print-table th,
.print-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.print-footer {
  margin-top: 20px;
}

.print-footer td {
  width: 33.333%;
  text-align: center;
}

.footer-line td {
  padding-top: 40px;
  text-align: left;
}

.footer-line td div {
  width: 50%;
  margin: 0 auto;
  border-bottom: 1px solid black;
}

.footer-line td p {
  width: 50%;
  margin: 5px auto 0;
}

@media print {
  @page {
    size: A4;
  }

  .print-section {
    width: auto;
    margin: 10px;
  }

  .page-section {
    padding: 0;
    margin: 0;
    border: none;
    page-break-before: always;
  }

  .print-title {
    display: block;
  }

  .print-table {
    margin: 30px 0;
  }

  .print-footer {
    margin-top: 30px;
  }

  .footer-line td {
    padding-top: 80px;
  }

  .footer-line td p {
    margin: 10px auto 0;
  }

  .print-button {
    display: none;
  }
}
</style>